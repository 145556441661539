import React from 'react'

import CountUp from 'react-countup';

export default class Block5 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){
    let {
      data
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          .overlay-black-dark:after {
            background: ${data.settings.tertiary_color};
            opacity: 0.4;
          }
        `}}
      />
    )
  }

  render() {
    let {
      data
    } = this.state

    let style = {};
    if(data.background_image){
      style = {backgroundImage: `url("${data.background_image.wide}")`, marginTop: data.settings.margin_top, marginBottom: data.settings.margin_bottom}
    }else{
      style = {backgroundColor: data.tertiary_color, marginTop: data.settings.margin_top, marginBottom: data.settings.margin_bottom}
    }

    return (
      <>
        {this._renderBlockStyle()}
        <div id={`section-${data.id}`} className="section-full content-inner-2 call-to-action text-white text-center bg-img-fix overlay-black-dark" style={style}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="m-b10" style={{color: data.settings.primary_color}}>
                  {data.title}
                </h2>
                <p className="m-b0" style={{color: data.settings.secondary_color}}>
                  {data.subtitle}
                </p>
                <a
                  className="site-button m-t20 outline outline-2 radius-xl"
                  href={data.primary_button.url}
                  style={{
                    color: data.primary_button.settings.primary_color,
                    backgroundColor: data.primary_button.settings.tertiary_color,
                    borderColor: data.primary_button.settings.tertiary_color,
                  }}
                >
                  {data.primary_button.title}
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

}
