import React from 'react';

import {Link} from 'react-router-dom';
import renderHTML from 'react-render-html';
import Backend from "../../../utils/Backend";

export default class Description extends React.Component{

	constructor(props) {
		super(props);

		this.state = {
			job: props.job,
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState(nextProps)
	}

	render(){
		let {
			job
		} = this.state

		return(
			<div className="col-lg-8 m-b50">
				<div className="job-info-box">
					<h3 className="m-t0 m-b10 font-weight-700 title-head">
						<a href={"#"} className="text-secondry m-r30">{job.title}</a>
					</h3>
					<ul className="job-info">
						<li><i className="ti-location-pin text-black m-r5"/> {job.address.city.name} </li>
					</ul><br/>
					<h5 className="font-weight-600">{window.Branding.Terminology.job} Description</h5>
					<div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"/>
					{job &&job.description && renderHTML(job.description)}
					<br/><br/>
					<a
						className="site-button"
						href={"javascript:;"}
						onClick={() => {
							Backend.saveExternalJobApplication(job)
							window.open(job.external_application_url, '_blank')
						}}
					>
						Apply
					</a>
				</div>
			</div>
		)
	}

}
