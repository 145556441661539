import React from "react";

import { withRouter } from "react-router-dom";

import renderHTML from "react-render-html";

import Backend from "../utils/Backend";
import General from "../utils/General";

import Banner from "./components/common/Banner";
import NotFound from "./components/common/NotFound";

import JobsList from "./components/job/List";

export class Company extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: props.company,
      website: props.website,
      job: props.job,
    };
  }

  componentDidMount() {
    this._loadCompany(this.props.history.location.pathname);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps);
  }

  _loadCompany(companySlug) {
    Backend.getCompany(companySlug)
      .then((company) => {
        this.setState({
          company,
        });
      })
      .catch((error) => {
        this.setState({ notFound: true });
      });
  }

  _setWebUrl(url){
    var pattern = /^((http|https|ftp):\/\/)/;
    if(!pattern.test(url)) {
        url = "http://" + url;
    }
    return url
  }

  _renderDetails(icon, text) {
    return (
      <li style={{padding: 4}}>
        <i className="material-icons" style={{ fontSize: 16, marginRight: 8 }}>
          { icon }
        </i>
        <span style={{fontSize: 20}}>{text}</span>
      </li>
    );
  }

  render() {
    let { website, company } = this.state;

    if (!company) return <NotFound website={website} />;

    return (
      <>
        <div className="page-content bg-white">
          <Banner image={company.banner} title={company.name } subtitle={company?.address?.city?.state?.country?.name}/>
          <div className={"content-area"}>
            <div className="container">
              <div className={"row"}>
                <div
                  className="float-circle-logo"
                  data-scroll-class="366px:pos-fixed"
                  style={{
                    maxWidth: 1280,
                    cursor: "pointer",
                  }}
                >
                  <div className="oval-initials">
                    {company.logo ? (
                      <img
                        src={company.logo.original}
                        className="logo img-rounded"
                        style={{ borderRadius: "50%" }}
                      />
                    ) : (
                      <div
                        className="symbol-label"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          background: "whitesmoke",
                        }}
                      >
                        <h1
                          className="m-0"
                          style={{
                            fontSize: "50px",
                            transform: "translateY(25px)",
                          }}
                        >
                          {General.getInitials(company.name)}
                        </h1>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row justify-content-between m-reorder-col">
                  <div className="col-md-4 col-lg-4 boxed bg--secondary boxed--lg m-order-2">
                    <h3>Company Details</h3>
                    <ul style={{ textAlign: "left" }}>
                      {company?.details.map((detail) => {
                        return this._renderDetails(detail.icon, detail.text);
                      })}
                    </ul>
                    <div className="row ml-0">
                      <div className="text-center mt--2">
                        {company.social &&
                          <ul className="social-list list-inline list--hover">
                            {/*
                            {company.social.website &&
                              <li style={{width: 20, marginRight: 12}}>
                                <a href={this._setWebUrl(company.social.website)} target={'_blank'}>
                                  <i className="material-icons icon icon--sm">website</i>
                                </a>
                              </li>
                            }
                            */}
                            {company.social.twitter &&
                              <li>
                                <a href={`https://www.twitter.com/${company.social.twitter}`} target={'_blank'}>
                                  <i className="socicon socicon-twitter icon icon--s"></i>
                                </a>
                              </li>
                            }
                            {company.social.facebook &&
                              <li>
                                <a href={`https://www.facebook.com/${company.social.facebook}`} target={'_blank'}>
                                  <i className="socicon socicon-facebook icon icon--s"></i>
                                </a>
                              </li>
                            }
                            {company.social.instagram &&
                              <li>
                                <a href={`https://www.instagram.com/${company.social.instagram}`} target={'_blank'}>
                                  <i className="socicon socicon-instagram icon icon--s"></i>
                                </a>
                              </li>
                            }
                            {company.social.linkedin &&
                              <li>
                                <a href={`https://www.linkedin.com/company/${company.social.linkedin}`} target={'_blank'}>
                                  <i className="socicon socicon-linkedin icon icon--s"></i>
                                </a>
                              </li>
                            }
                          </ul>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-7 description">
                    <h2>About</h2>
                    <p className="lead img-no-max-height" />
                    {company.description && renderHTML(company.description)}
                  </div>
                </div>
              </div>

              <div>
                <h2 className="text-center mt-4">Jobs</h2>
              </div>

              <section
                className="text-center"
                id="jobs"
                style={{ marginTop: "90px" }}
              >
                <JobsList
                  website={website}
                  companyId={company.id}
                />
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Company);
