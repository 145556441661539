import React from "react";

import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { InView } from "react-intersection-observer";

import General from "../../../utils/General";

class CustomMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      map: JSON.parse(JSON.stringify(props.map)),
    };
  }

  componentWillReceiveProps(nextState) {
    this.setState({
      map: JSON.parse(JSON.stringify(nextState.map)),
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const oldAddress = this.state.map.address;
    const newAddress = nextState.map.address;

    if (JSON.stringify(oldAddress) !== JSON.stringify(newAddress)) {
      return true;
    }

    return false;
  }

  _renderMarker(marker) {
    return <Marker position={marker} />;
  }

  render() {
    const { map } = this.state;
    const { address } = map;
    const { latitude, longitude } = address;

    const marker = {
      lat: latitude,
      lng: longitude,
    };

    const style = {
      width: "100%",
      height: this.props.variation === "map_top_text_bottom" ? "400px" : "450px"

    };

    return (
      <InView triggerOnce={true}>
        {({ inView, ref, entry }) => (
          <div ref={ref}>
            {inView && (
              <Map
                key={General.uuid()}
                google={this.props.google}
                zoom={15}
                initialCenter={marker}
                center={marker}
                streetViewControl={false}
                mapTypeControl={false}
                style={style}
              >
                {this._renderMarker(marker)}
              </Map>
            )}
          </div>
        )}
      </InView>
    );
  }
}

class CustomMarker extends Marker {
  componentDidUpdate(prevProps) {
    if (this.props.shouldRender) {
      this.marker.setMap(null);
      this.renderMarker();
    }
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GMAPS_KEY,
})(CustomMap);
