export default class General {

  static uuid() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }

  static debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  static snakeCaseToTitleCase(str){
    let words = str.replace('_',' ')
    return General.toTitleCase(words)
  }

  static slugify(str){
    let words = str.replaceAll(" ", "-");
    return words.toLowerCase()
  }

  static toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  static getInitials(str){
    var initials = str.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials
  }

  static getRandomColorState(){
    let colors = ["info", "success", "warning", "danger", "dark", "primary", "brand"]
    let color = colors[Math.floor(Math.random()*colors.length)];
    return color
  }

  static getCurrency(currency){
    switch (currency) {
      case 'eur':
        return '€';
      case 'gbp':
        return '£';
      case 'usd':
      case 'cad':
        return '$';
      default:
        return '€';
    }
  }

  static getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  static getUrlValue(params, value, parseValue = true){
    if(value in params){
      if(!parseValue){
        return params[value]
      }
      return params[value].replace('-', ' ')
    }
    return ''
  }

  static parseRealexResponse(obj){
    Object.keys(obj).forEach(function (key) {
      var k = key.toLowerCase();

      if (k !== key) {
        obj[k] = atob(obj[key]);
        delete obj[key];
      }
    });
    return (obj);
  }

  static getYoutubeVideoId(youtubeUrl) {
    if (!youtubeUrl) {
      return null;
    }

    var regExp =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = youtubeUrl.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return null;
    }
  }
}
