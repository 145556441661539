import React from 'react'

import Link from 'react-router-dom'
import SearchBar from "../common/SearchBar";

import General from "../../../utils/General";

export default class Block2 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){
    let {
      data
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${data.id} {
            background-color: ${data.settings.tertiary_color} !important;
          }
          #section-${data.id} {
            margin-top: ${data.settings.margin_top}px;
            margin-bottom: ${data.settings.margin_bottom}px;
          }
        `}}
      />
    )
  }

  render() {
    let {
      data,
      showVideo
    } = this.state

    let videoUrl = data.background_video?.url
    let videoId = General.getYoutubeVideoId(videoUrl)

    let videoContainerClassName = "offset-md-2 col-md-8 offset-lg-3 col-lg-6"

    if(data.variation === "large"){
      videoContainerClassName = "col-md-12 offset-lg-1 col-lg-10"
    }
    return (
      <>
        {this._renderBlockStyle()}
        <div id={`section-${data.id}`} className="section-full content-inner-2">
          <div className="container">
            <div className="section-head text-black text-center mb-0">
              <h3 className="m-b0" style={{color: data.settings.primary_color}}>{data.title}</h3>
              <p style={{color: data.settings.secondary_color}}>{data.subtitle}</p>

              {/* stats : Video row */}
              <div class="row mt-4 text-center" key={videoId+data.foreground_image?.id}>
                <div class={videoContainerClassName}>
                  <div class="video-cover border--round">
                      { !showVideo ? (
                        <>
                          <div class="background-image-holder">
                              <img alt="" src={data.foreground_image.original} style={{objectFit: 'cover'}} />
                           </div>
                          <div
                            class="video-play-icon"
                            onClick={() => this.setState({ showVideo: true })}
                          >
                          </div>
                        </>
                      ) : (
                        <iframe
                          width="100%"
                          height="100%"
                          src={`https://www.youtube-nocookie.com/embed/${videoId}?controls=0&amp;autoplay=1&showinfo=0&mute=0&loop=1&playlist=${videoId}`}
                          frameborder="0"
                          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        />
                      )
                    }
                  </div>
                  { data.body &&
                    <div>
                      <span
                        className="fw6"
                        style={{color: data.settings.secondary_color}}
                      >
                        { data.body }
                      </span>
                    </div>
                  }
                </div>
              </div>
              {/* stats : Video end */}
            </div>
          </div>
        </div>
      </>
    )
  }

}
