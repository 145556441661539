import React from 'react'

export default class Block8 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){
    let {
      data
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${data.id} {
            background-color: ${data.settings.tertiary_color} !important;
          }
          #section-${data.id} {
            margin-top: ${data.settings.margin_top}px;
            margin-bottom: ${data.settings.margin_bottom}px;
          }
        `}}
      />
    )
  }

  render() {
    let {
      data
    } = this.state

    return (
      <>
        {this._renderBlockStyle()}
        <div id={`section-${data.id}`} className="section-full content-inner">
          <div className="container">
            <div className="row">
              {
                data.image_infos.map(imageInfo => {
                  return (
                    <div className="col-lg-3 col-sm-6 col-md-6 m-b30" >
                      <a href={imageInfo.button?.url}
                        className="city-bx align-items-end d-flex"
                        style={{backgroundImage: `url("${imageInfo.image.small}"`}}
                      >
                        <div className="city-info">
                          <h5 style={{color: data.settings.primary_color}}>{imageInfo.title}</h5>
                          <span style={{color: data.settings.secondary_color}}>{imageInfo.subtitle}</span>
                        </div>
                      </a>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </>
    )
  }

}
