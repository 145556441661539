import React from 'react'

import {Link} from 'react-router-dom'
import Select from "../common/Select";

import SearchBar from "../common/SearchBar";

import Backend from "../../../utils/Backend";
import Template from "../../../utils/Template";
import Color from "../../../utils/Color";


export default class Block1 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      jobBoard: props.jobBoard,
      searchTerm: props.data.body
    }
  }

  componentDidMount() {
    this._loadLocations()
    Template.searchInput()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _loadLocations() {
    let {
      jobBoard,
    } = this.state


		Backend.getJobPostForm(jobBoard.id)
			.then(form => {
				this.setState({
					locations: form.cities
				}, () => {
					this._formatLocations()
				})
			})
  }

  _formatLocations() {
    let {
      locations,
    } = this.state

    let searchableLocations = []

    locations.map(city => {
      searchableLocations.push({value: city.id, label: city.name, type: 'city'})
		})

    searchableLocations.sort((a, b) => a.label.localeCompare(b.label))

    let selectedLocation = this._getSelectedLocationValue(searchableLocations)

    this.setState({searchableLocations, selectedLocation})
  }

  _getSelectedLocationValue(searchableLocations) {

    let {
      cityId,
      stateId,
      countryId,
    } = this.state

    if (searchableLocations == null) {
      return null
    }

    return searchableLocations.find(option => {
      return cityId == option.value
    })
  }

  _handleLocationChange(location) {
    let countryId = '';
    let stateId = '';
    let cityId = '';
    if(!location){
      // do nothing
    } else if (location.type === 'country') {
      countryId = location.value
    } else if(location.type === 'state'){
      stateId = location.value
    } else if(location.type === 'city'){
      cityId = location.value
    }
    this.setState({selectedLocation: location, countryId, stateId, cityId})
  }

  _handleSearch() {
    let {
      searchTerm,
      countryId,
      stateId,
      cityId,
      jobBoard,
      premium
    } = this.state

    window.location.href = `/search/jobs?searchTerm=${searchTerm || ''}&cityId=${cityId || ''}&stateId=${stateId || ''}&countryId=${countryId || ''}&job_board_id=${jobBoard.job_board}`
  }

  _renderBlockStyle(){
    let {
      data
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${data.id} a.custom-btn-class,
          #section-${data.id} button.custom-btn-class{
            background-color: ${data.primary_button.settings.tertiary_color} !important;
            border-color: ${data.primary_button.settings.tertiary_color} !important;
            color: ${data.primary_button.settings.primary_color} !important;
          }
          #section-${data.id} button.custom-btn-class:hover {
            background-color: ${Color.brightness(data.primary_button.settings.tertiary_color, 20)} !important;
          }
          #section-${data.id} {
            margin-top: ${data.settings.margin_top}px;
            margin-bottom: ${data.settings.margin_bottom}px;
            background-color: ${data.settings.tertiary_color};
          }
        `}}
      />
    )
  }

  render() {
    let {
      data,
      jobBoard,
      searchTerm,
      selectedLocation,
      searchableLocations,
    } = this.state


    let style = {};
    if(data.background_image){
      style = {backgroundImage: `url("${data.background_image.wide}")`}
    }else{
      style = {backgroundColor: data.settings.tertiary_color}
    }

    return (
      <>
        {this._renderBlockStyle()}
        {
          data.variation === "large" &&
          <div id={`section-${data.id}`} className="dez-bnr-inr dez-bnr-inr-md" style={style}>
            <div className="container">
              <div className="dez-bnr-inr-entry align-m">
                <div className="find-job-bx">
                  <a className="button-sm custom-btn-class" style={{color: data.settings.secondary_color, fontSize: data.extra?.subtitle_font_size || 18}}>{data.subtitle}</a>
                  <h2 style={{color: data.settings.primary_color}}>{data.title}</h2>
                  <form className="dezPlaceAni">
                    <div className="row">
                      <div className="col-lg-5 col-md-5">
                        <div className="form-group">
                          <label>{window.Branding.Terminology.job} Title, Keywords, or Phrase</label>
                          <div className="input-group">
                            <input
                              type="text"
                              autofocus={searchTerm && "autofocus"}
                              className="form-control"
                              value={searchTerm}
                              style={{
                                borderColor: data.primary_button?.settings.tertiary_color
                              }}
                              onChange={e => {
                                let value = e.target.value;
                                searchTerm = value;
                                this.setState({searchTerm})
                              }}
                            />
                            <div className="input-group-append">
                              <span
                                className="input-group-text"
                                style={{
                                  color: data.primary_button?.settings.tertiary_color,
                                  borderColor: data.primary_button?.settings.tertiary_color
                                }}
                              >
                                <i className="fa fa-search"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-5" style={{zIndex: 9999999}}>
                        <div className="form-group">
                          { searchableLocations &&
                            <Select
                                value={selectedLocation?.value}
                                options={searchableLocations}
                                getOptionLabel={searchableLocation => searchableLocation.label}
                                getOptionValue={searchableLocation => searchableLocation.value}
                                placeholder="Select Location"
                                className="form-control h-auto border-0 c-selectbox"
                                isClearable={true}
                                onSelected={option => {
                                  this._handleLocationChange(option)
                                }}
                            />
                          }
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <button
                          type="submit"
                          className="site-button btn-block custom-btn-class"
                          onClick={e => {
                            e.preventDefault()
                            this._handleSearch()
                          }}
                        >
                          {data.primary_button.title}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        }

        {
          data.variation === "small" &&
          <div id={`section-${data.id}`}>
            <div className="dez-bnr-inr dez-bnr-full-big " style={style}>
              <div className="container">
                <div className="dez-bnr-inr-entry">
                  <h1 className="font-size-main " style={{color: data.settings.primary_color}}>{data.title}</h1>
                  <div className="breadcrumb-row">
                    <ul className="list-inline">
                      <p className="m-t40" style={{color: data.settings.secondary_color}}>
                        {data.subtitle}
                      </p>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <SearchBar
              jobBoard={jobBoard}
              searchTerm={searchTerm}
              accentColor={data.primary_button?.settings.tertiary_color}
            />
          </div>
        }

        {
          data.variation === "medium" &&
          <div id={`section-${data.id}`}>
            <div className="dez-bnr-inr " style={style}>
              <div className="container">
                <div className="dez-bnr-inr-entry">
                  <h1 style={{color: data.settings.primary_color}}>{data.title}</h1>
                  <div className="breadcrumb-row">
                    <ul className="list-inline">
                      <li style={{color: data.settings.secondary_color}}>{data.subtitle}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <SearchBar
              jobBoard={jobBoard}
              searchTerm={searchTerm}
              accentColor={data.primary_button?.settings.tertiary_color}
            />
          </div>
        }
      </>
    )
  }

}

const customStyles = {
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    if (data.type === 'state') {
      return styles;
    }
    return {
      ...styles,
      backgroundColor: isFocused ? '#B2D4FF' : '#f5f5f5',
      color: isSelected ? '#fff' : '#666666',
      cursor: 'default',
    };
  },
  control: (styles) => {
    return {
      ...styles,
      border: '1px solid #ececec',
      borderRadius: 6,
      background: '#fcfcfc',
      fontWeight: 400,
      fontSize: 16,
      minHeight: 45,
      height: 45,
    }
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      height: 44,
      padding: '0 10px',
      overflow: 'unset'
    }
  },
  input: (styles) => {
    return {
      ...styles,
      margin: 0,
      paddingLeft: 1
    }
  },
  indicatorSeparator: (styles) => {
    return {
      display: 'none'
    }
  },
  indicatorsContainer: (styles) => {
    return {
      ...styles,
      height: 44
    }
  },
};
