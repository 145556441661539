import React from 'react'

import Link from 'react-router-dom'
import SearchBar from "../common/SearchBar";

export default class Block2 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){
    let {
      data
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${data.id} {
            background-color: ${data.settings.tertiary_color} !important;
          }
          #section-${data.id} {
            margin-top: ${data.settings.margin_top}px;
            margin-bottom: ${data.settings.margin_bottom}px;
          }
        `}}
      />
    )
  }

  render() {
    let {
      data
    } = this.state

    return (
      <>
        {this._renderBlockStyle()}
        <div id={`section-${data.id}`} className="section-full content-inner-2">
          <div className="container">
            <div className="section-head text-black text-center mb-0">
              <h2 className="m-b0" style={{color: data.settings.primary_color}}>{data.title}</h2>
              <p style={{color: data.settings.secondary_color}}>{data.subtitle}</p>
            </div>
          </div>
        </div>
      </>
    )
  }

}
