import React from 'react'

import renderHTML from 'react-render-html';
import {Link} from 'react-router-dom'
import Backend from "../../../utils/Backend";
import moment from "moment";

export default class Block7 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    }
  }

  componentDidMount() {
    this._loadArticles()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps, () => this._loadArticles())
  }

  _loadArticles(){
    let {
      data
    } = this.state

    Backend.getArticles(data.variation).then(response => {
      this.setState({
        articles: response.results
      })
    }).catch(e => {
      console.log(e.message)
    })
  }

  _renderBlockStyle(){
    let {
      data
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${data.id} {
            background-color: ${data.settings.tertiary_color} !important;
            margin-top: ${data.settings.margin_top}px;
            margin-bottom: ${data.settings.margin_bottom}px;
          }
        `}}
      />
    )
  }

  render() {
    let {
      data,
      articles
    } = this.state

    return (
      <>
        {this._renderBlockStyle()}
        <div id={`section-${data.id}`} className="section-full content-inner">
          <div className="container">
            <div className="section-head text-black text-center m-b20">
              <h2 className="m-b10" style={{color: data.settings.primary_color}}>{data.title}</h2>
              <p style={{color: data.settings.secondary_color}}>{data.subtitle}</p>
            </div>
            <div className="row">

              {
                articles?.map(article => {
                  return (
                    <div className="col-lg-4 col-md-4 col-sm-12 m-b30">
                      <div className="item p-3">
                        <div className="blog-post blog-grid blog-style-1">
                          <div className="dez-post-media dez-img-effect radius-sm">
                            <a href={`/article/${article.slug}`}>
                              <img src={article.thumbnail.wide} style={{objectFit: 'cover', height: 225}} />
                            </a>
                          </div>
                          <div className="dez-info">
                            <div className="dez-post-title mt-2">
                              <h5 className="post-title font-20">
                                <a style={{color: data.settings.quaternary_color}} href={`/article/${article.slug}`}>{article.title}</a>
                              </h5>
                            </div>
                            <div className="dez-post-text" style={{color: data.settings.quinary_color}}>
                              {article.subtitle || renderHTML(article.body.slice(0, 100) + "...")}
                            </div>
                            <div className="dez-post-readmore blog-share">
                              <a href={`/article/${article.slug}`} title="READ MORE" rel="bookmark" className="site-button-link">
                                <span
                                  className="fw6"
                                  style={{color: data.settings.senary_color}}
                                >
                                  READ MORE
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }

            </div>

          </div>
        </div>
      </>
    )
  }

}