import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.Api = {};

window.Api.Base = env.REACT_APP_API_BASE

window.Api.User = {};

window.Api.User.Base = window.Api.Base + "/user"

window.Api.User.Login                 = window.Api.User.Base + "/login"
window.Api.User.Logout                = window.Api.User.Base + "/logout"
window.Api.User.Info                  = window.Api.User.Base + "/info"
window.Api.User.RequestResetPassword  = window.Api.User.Base + "/requestResetPassword"
window.Api.User.ResetPassword         = window.Api.User.Base + "/resetPassword"
window.Api.User.RefreshToken          = window.Api.User.Base + "/login/refresh"

window.Api.Register                   = window.Api.Base + "/companies/members"

window.Api.Jobs                       = window.Api.Base + "/jobs"
window.Api.Companies                  = window.Api.Base + "/companies"
window.Api.JobBoardMembers            = window.Api.Base + "/job-board-members"
window.Api.Pages                      = window.Api.Base + "/pages"
window.Api.Blocks                     = window.Api.Base + "/blocks"
window.Api.ContentBlock               = window.Api.Base + "/content-blocks"
window.Api.Images                     = window.Api.Base + "/images"
window.Api.Articles                   = window.Api.Base + "/articles"
window.Api.Categories                 = window.Api.Base + "/job-board-categories"
window.Api.SearchableLocations        = window.Api.Base + "/searchable-locations"
window.Api.Forms                      = window.Api.Base + "/forms"
