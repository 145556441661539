import React from 'react'

import Color from "../../../utils/Color";

export default class Block10 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){
    let {
      data
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${data.id} {
            background-color: ${data.settings.tertiary_color} !important;
          }
          #section-${data.id} {
            margin-top: ${data.settings.margin_top}px;
            margin-bottom: ${data.settings.margin_bottom}px;
          }
          #section-${data.id} a.custom-btn-class,
          #section-${data.id} button.custom-btn-class{
            background-color: ${data.primary_button.settings.tertiary_color} !important;
            border-color: ${data.primary_button.settings.tertiary_color} !important;
            color: ${data.primary_button.settings.primary_color} !important;
          }
          #section-${data.id} button.custom-btn-class:hover {
            background-color: ${Color.brightness(data.primary_button.settings.tertiary_color, 20)} !important;
          }
        `}}
      />
    )
  }

  render() {
    let {
      data
    } = this.state

    let text = (
      <div class="col-md-12 col-lg-6 m-b20">
        {data.title &&
          <h2 class="m-b5">{data.title}</h2>
        }
        {data.subtitle &&
          <h3 class="fw4">{data.subtitle}</h3>
        }
        {data.body &&
          <p class="m-b15">
            {data.body}
          </p>
        }
        <a class="site-button custom-btn-class" href={data.primary_button.url}>
          {data.primary_button.title}
        </a>
      </div>
    )

    let image = (
      <div class="col-md-12 col-lg-6">
        <img src={data.foreground_image?.original} alt=""/>
      </div>
    )

    let imageLeft = data.variation == "image_left_text_right"

    let content = imageLeft ? [image, text] : [text, image]
    return (
      <>
        {this._renderBlockStyle()}
        <div id={`section-${data.id}`} className="content-block">
          <div class="section-full content-inner overlay-white-middle">
            <div class="container">
              <div class="row align-items-center m-b50">
                {content}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

}
