import React from "react";

import InnerHTML from 'dangerously-set-html-content'

import PageWrapper from "./components/common/PageWrapper";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Banner from "./components/common/Banner";
import NotFound from "./components/common/NotFound";

import Detail from "./components/job/Detail";
import Description from "./components/job/Description";
import ContentBlock from "./components/blocks/ContentBlock";
import Backend from "../utils/Backend";

export default class Page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: props.page,
      website: props.website,
    };
  }

  componentDidMount() {
    // this._loadPage(this.props.match.params.job_slug)
    // this._loadPage('index')
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ nextProps });
    // this._loadPage(nextProps.match.params.job_slug)
    // this._loadPage('index')
  }

  _loadPage(pageSlug) {
    let { websiteUrl } = this.state;

    Backend.getPage(pageSlug, "page", websiteUrl)
      .then((page) => {
        this.setState({
          page,
        });
      })
      .catch(() => {
        this.setState({ notFound: true });
      });
  }

  render() {
    let { page, website } = this.state;

    if (!page) return <NotFound website={website} />;

    return (
      <>
      <div className="page-content bg-white">
          {
            page.content_blocks.map(contentBlock => {
              return (
                <ContentBlock
                  data={contentBlock}
                  jobBoard={website}
                />
              )
            })
          }
        </div>
        { page.script && <InnerHTML html={page.script} /> }
      </>
    );
  }
}
