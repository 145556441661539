import React from 'react'
import moment from "moment";

import {Link} from 'react-router-dom'
import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Color from "../../../utils/Color";

export default class Block4 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      jobBoard: props.jobBoard,
    }
  }

  componentDidMount() {
    let {
      data
    } = this.state

    if(data.variation === "featured_jobs"){
      this._loadJobs("featured")
    }else{
      this._loadJobs("latest")
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _loadJobs(type){
    Backend.getJobs(type).then(response => {
      this.setState({
        jobs: response.results
      })
    })
  }

  _renderBlockStyle(){
    let {
      data
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${data.id} {
            background-color: ${data.settings.tertiary_color} !important;
            margin-top: ${data.settings.margin_top}px;
            margin-bottom: ${data.settings.margin_bottom}px;
          }
          #section-${data.id} a.site-button:hover {
            background-color: ${Color.brightness(data.primary_button.settings.tertiary_color, 20)} !important;
          }
        `}}
      />
    )
  }

  _replaceNone(value){
    return value.replace(', None','');
  }

  render() {
    let {
      jobs,
      data,
      jobBoard
    } = this.state

    return (
      <>
        {this._renderBlockStyle()}
        <div id={`section-${data.id}`} className="section-full bg-white content-inner-2">
          <div className="container">
            <div className="d-flex job-title-bx section-head">
              <div className="mr-auto">
                <h2 className="m-b5" style={{color: data.settings.primary_color}}>{data.title}</h2>
                <h6 className="fw4 m-b0" style={{color: data.settings.secondary_color}}>{data.subtitle}</h6>
              </div>
              <div className="align-self-end">
                <a
                  href={"/search/jobs"}
                  className="site-button button-sm"
                  style={{
                    color: data.primary_button.settings.primary_color,
                    backgroundColor: data.primary_button.settings.tertiary_color
                  }}
                >
                  {data.primary_button.title} <i className="fa fa-long-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-9 m-auto">
                <ul className="post-job-bx browse-job">
                  {
                    jobs?.map(job => {
                      return (
                        <li>
                          <div className="post-bx" >
                            <div className="d-flex m-b30">

                              <div className="job-post-company">
                                {
                                  job.company.logo ?
                                    <img alt="" src={job.company.logo.original} style={{objectFit: 'cover'}} />
                                  :
                                    <h3 className="initials">
                                      {General.getInitials(job.company.name)}
                                    </h3>
                                }
                              </div>

                              <div className="job-post-info">
                                <h4>
                                  <a
                                    className="ellipsis-1"
                                    href={`/companies/${job.company.slug}/jobs/${job.slug}`}
                                    onClick={e => {
                                      if(!jobBoard.search_result_redirect){
                                        return
                                      }
                                      e.preventDefault();
                                      Backend.saveExternalJobApplication(job)
                                      window.open(job.external_application_url, '_blank')
                                    }}
                                  >
                                    {job.title}
                                  </a>
                                </h4>
                                {!jobBoard.hide_company_names &&
                                  <div className='c-name'>
                                    <i className="fa fa-building-o icon"></i>
                                    <span className='ellipsis-1'>{job.company.name}</span>
                                  </div>
                                }
                                <ul>
                                  <li><i className="fa fa-map-marker"></i>{this._replaceNone(job.address.raw)}</li>
                                  <li><i className="fa fa-clock-o"></i> Published {moment(job.created_at).fromNow()}</li>
                                </ul>
                                { job.external_impression_url &&
                                  <img
                                    width={1}
                                    height={1}
                                    className="indeed-tmn-pxl"
                                    data-src={job.external_impression_url}
                                    style={{display: 'block', marginBottom:-1}}
                                  />
                                }
                              </div>

                            </div>
                            <div className="d-flex">
                              { !jobBoard.hide_job_categories &&
                                <div className="job-time mr-auto">
                                  <span>{job.contract?.name}</span>
                                </div>
                              }
                            </div>
                          </div>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

}
