import React from 'react';

import {Link} from 'react-router-dom';

export default class Banner extends React.Component{

	constructor(props) {
		super(props);

		this.state = {
			image: props.image,
			title: props.title,
			subtitle: props.subtitle,
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState(nextProps)
	}

	render(){
		let {
			image,
			title,
			subtitle
		} =  this.state

		let imageUrl = image?.original || 'https://d1sd4umgax6qlh.cloudfront.net/images/small_image_20-07-2021_11:18:23.jpg'
		return(
			<div className="dez-bnr-inr overlay-black-middle" style={{backgroundImage: `url(${imageUrl})`}}>
				<div className="container">
					<div className="dez-bnr-inr-entry">
						<h1 className="text-white">{title}</h1>
						{
							subtitle &&
							<div className="breadcrumb-row">
								<ul className="list-inline">
									<li>{subtitle}</li>
								</ul>
							</div>
						}
					</div>
				</div>
			</div>
		)
	}

}
