import React from 'react';

import Backend from "../utils/Backend";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import PageWrapper from "./components/common/PageWrapper";
import Banner from "./components/common/Banner";
import NotFound from "./components/common/NotFound";

import Detail from "./components/job/Detail";
import Description from "./components/job/Description";

export default class Job extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      job: props.job,
      website: props.website
    }
  }

  componentDidMount() {
    // this._loadJob(this.props.jobSlug)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // this.setState({nextProps}, () => this._loadJob(nextProps.jobSlug))
  }

  _loadJob(jobSlug){
    Backend.getJob(jobSlug)
      .then(job => {
        this.setState({
          job
        })
      }).catch(() => {
      this.setState({notFound: true})
    })
  }

  render() {
    let {
      job,
      website,
      show,
      items,
      showExternalJobModal
    } = this.state

    if(!job) return <NotFound website={website}/>

    return (
      <>
        <div className="page-content bg-white">
          <Banner
            image={job.company.banner}
            title={job.title}
            subtitle={job.address.city.name}
          />
          <div className={"content-block"}>
            <div className="section-full content-inner-1">
              <div className="container">
                <div className="row">
                  <Detail
                    job={job}
                  />
                  <Description
                    job={job}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }

}
