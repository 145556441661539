import React from "react";

import General from "../../../utils/General";

export default class Block13 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderBlockStyle() {
    let { data } = this.state;

    return (
      <style
        dangerouslySetInnerHTML={{
          __html: `
          #section-${data.id} {
            background-color: ${data.settings.tertiary_color} !important;
            margin-top: ${data.settings.margin_top}px;
            margin-bottom: ${data.settings.margin_bottom}px;
          }
        `,
        }}
      />
    );
  }

  _renderDetails(icon, text) {
    return (
      <li style={{padding: 4}}>
        <i className="material-icons" style={{ fontSize: 16, marginRight: 8 }}>
          { icon }
        </i>
        <span style={{fontSize: 20}}>{text}</span>
      </li>
    );
  }

  render() {
    let { data } = this.state;

    return (
      <>
        {this._renderBlockStyle()}
        <div id={`section-${data.id}`} className="section-full content-inner">
          <div className="container">
            <div className="section-head text-black text-center m-b20">
              <h2
                className="m-b10"
                style={{ color: data.settings.primary_color }}
              >
                {data.title}
              </h2>
              <p style={{ color: data.settings.secondary_color }}>
                {data.subtitle}
              </p>
            </div>
            <div className="row">
              {data?.companies?.map((company) => {
                return (
                  <>
                    <div className="col-md-4 tiles-v1">
                      <div className="feature feature-1">
                        <div id="ember873" className="loaded ember-view">
                          <a href={`/companies/${company.slug}`}>
                            <img
                              alt="Image"
                              className="featured-image-cover"
                              src={
                                company.banner?.original ||
                                "https://d1sd4umgax6qlh.cloudfront.net/images/small_image_20-07-2021_11:18:23.jpg"
                              }
                              style={{
                                width: "100%",
                                height: 225,
                                objectFit: "cover",
                              }}
                            />
                          </a>
                        </div>
                        <div className="feature__body boxed boxed--border featured-cards">
                          <div className="s-logo">
                            {company.logo ? (
                              <img
                                src={company.logo.original}
                                className="logo img-rounded"
                              />
                            ) : (
                              <div className="symbol-label">
                                <h1
                                  className="my-2"
                                  style={{ fontSize: "30px" }}
                                >
                                  {General.getInitials(company.name)}
                                </h1>
                              </div>
                            )}
                          </div>
                          <h4>{company.name}</h4>
                          <ul style={{ textAlign: "left" }}>
                            {company?.details.map((detail) => {
                              return this._renderDetails(
                                detail.icon,
                                detail.text
                              );
                            })}
                          </ul>
                          <a
                            style={{
                              backgroundColor: "#4286f7",
                              borderColor: "#4286f7",
                            }}
                            href={`/companies/${company.slug}`}
                            className="btn btn--primary"
                          >
                            <span className="btn__text">View {window.Branding.Terminology.jobs}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}
