import React from 'react';

import {Link} from 'react-router-dom';
import Template from "../../../utils/Template";

export default class Header extends React.Component{

	constructor(props) {
		super(props);

		this.state = {
			menu: props.menu,
			website: props.website
		}
	}

	_renderBlockStyle(){
		let {
			data
		} = this.state

		return (
			<style
				dangerouslySetInnerHTML={{__html: `
          a.custom-hover-class:hover{
            color: #fff !important;
          }
        `}}
			/>
		)
	}

	_renderMenu(menu){
		return menu.nav_items.map(navItem => {
			return (
				<li className="nav-item">
					<a style={{color: menu.settings.primary_color}} href={navItem.url} className="nav-link custom-hover-class">
						{navItem.text}
					</a>
				</li>
			)
		})
	}

	render(){
		let {
			menu,
			website
		} = this.state

		return(
			<>
				{this._renderBlockStyle()}
				<nav style={{backgroundColor: menu.settings.tertiary_color || '#fff'}} className="navbar navbar-expand-sm navbar-light g-header">
					<div className="container">

						<a href={"/"} className="navbar-brand">
							{
								menu.primary_image ? (
									<img src={menu.primary_image.small} alt="img" />
								) : (
									<span style={{color: "white"}}>{website.name}</span>
								)
							}
						</a>

						<button className="navbar-toggler b-toggler" type="button" data-toggle="collapse" data-target="#mobile-menu">
							<span className="navbar-toggler-icon"></span>
						</button>

						<div className="collapse navbar-collapse" id="mobile-menu">

							<ul className="navbar-nav mr-auto">
								{ this._renderMenu(menu) }
							</ul>

							<a href={"/search/jobs"} className="site-button my-2 my-md-0"><i className="fa fa-suitcase"/>
								{` ${window.Branding.Terminology.jobs}`}
							</a>

						</div>

					</div>
				</nav>
			</>
		)
	}

}
