export default class Template {

  static sideBar(){
    let btn = document.querySelector('.navicon');
    let aaa = document.querySelector('.myNavbar ');

    function toggleFunc() {
      return aaa.classList.toggle("show");
    }

    btn.addEventListener('click', toggleFunc);


    // Sidenav li open close
    let navUl = [].slice.call(document.querySelectorAll('.navbar-nav > li'));
    for (let y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener('click', function () { checkLi(this) });
    }

    function checkLi(current) {
      navUl.forEach(el => el.classList.remove('open'));
      current.classList.add('open');
    }
  }

  static searchInput(){
    let i = 0;

    let inputSelector = document.querySelectorAll('input, textarea');

    for (i = 0; i < inputSelector.length; i++) {
      inputSelector[i].addEventListener('focus', function(event) {
        return this.parentElement.parentElement.classList.add("focused");
      });
    }


    for (i = 0; i < inputSelector.length; i++) {
      inputSelector[i].addEventListener('blur', function(event) {
        let inputValue = this.value;
        if ( inputValue === '' ) {
          this.parentElement.parentElement.classList.remove('filled');
          this.parentElement.parentElement.classList.remove('focused');
        } else {
          this.parentElement.parentElement.classList.add('filled');
        }
      });
    }
  }

}
