import React, { Component } from 'react';


import Color from '../../../utils/Color'
import MetaTags from '../../../utils/MetaTags'

export default class Branding extends Component  {

  render(){

    let { website, page, job, article } = this.props
    let primaryColor = website.primary_color
    let secondaryColor = website.secondary_color

    let textOnPrimaryColor = "#fff"
    let primaryColorDarkened = Color.brightness(primaryColor, -80)
    let primaryColorDark = Color.brightness(primaryColor, -40)

    let primaryColorBrightened = Color.brightness(primaryColor, 20)
    let primaryColorBright = Color.brightness(primaryColor, 10)
    let primaryColorLightened = Color.alpha(primaryColor, 0.1)
    let primaryColorFaded = Color.alpha(primaryColor, 0.08)
    let primaryColorShade = Color.alpha(primaryColor, 0.02)


    let secondaryColorDarkened = Color.brightness(secondaryColor, -20)
    let secondaryColorLightened = Color.alpha(secondaryColor, 0.05)

    let title = website.name
    let { description, keywords, favicon } = website

    let logo = favicon
    if(page){
      title = page.title + " - " + website.name
      description = page.description
      keywords = page.keywords
    }
    else if(job){
      logo = job.company
      title = job.title
      description = job.description
    }
    else if(article){
      logo = article.company
      title = article.title
      description = article.body
    }

    return (
      <>
        {
          MetaTags.render(
            title,
            description,
            keywords,
            window.location.href,
            logo?.small,
            "type",
            true,
            favicon?.small
          )
        }

        <style
          dangerouslySetInnerHTML={{__html: `
            .post-job-bx > li:nth-of-type(2n+1) .post-bx {
                background-color: ${primaryColorShade};
            }
            .post-job-bx .post-bx {
                border-color: ${primaryColorLightened};
            }
            .post-job-bx .post-bx:hover {
                border-color: ${primaryColor};
            }
            .post-author a,
            .dez-post-meta i,
            .dez-post-meta ul li,
            .recent-posts-entry .post-date,
            .post-title a:hover {
                color: ${primaryColor};
            }
            .job-post-company {
              background-color: ${primaryColorLightened};
            }
            .job-time span {
              color: ${primaryColor};
              background-color: ${primaryColorLightened};
            }
            .post-job-bx ul li,
            .post-job-bx .c-name {
              color: ${primaryColor};
            }
            .city-bx:hover:after {
              background-color: ${primaryColor};
            }
            .site-button {
              background-color: ${primaryColor};
            }
            .site-button:hover {
              background-color: ${primaryColorBrightened};
            }
            .g-header .navbar-nav .nav-item:hover .nav-link {
              color: white;
              background-color: ${primaryColor} !important;
            }
            .dez-img-overlay1:before {
              background: ${primaryColorDarkened} !important;
              background: radial-gradient(ellipse at center,
                ${primaryColorBrightened} 0%,
                ${primaryColorDark} 49%,
                ${primaryColorDarkened} 100%) !important;
            }
            .overlay-black-middle:after {
              background: radial-gradient(ellipse at center,
                ${primaryColorDark} 0%,
                ${primaryColorDark} 49%,
                ${primaryColorDarkened} 100%) !important;
            }
          `}}
        />
      </>
    )
  }


}
