import React from "react";

import Map from "../common/Map";

export default class Block14 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps);
  }

  _renderBlockStyle() {
    let { data } = this.state;

    const height = data.variation === "map_top_text_bottom" ? "600px" : "450px";

    return (
      <style
        dangerouslySetInnerHTML={{
          __html: `
          #section-${data.id} {
            background-color: ${data.settings.tertiary_color} !important;
            height: ${height};
          }
          #section-${data.id} {
            margin-top: ${data.settings.margin_top}px;
            margin-bottom: ${data.settings.margin_bottom}px;
          }
        `,
        }}
      />
    );
  }

  _renderMap() {
    let { data } = this.state;

    return (
      <div style={{ position: "relative" }}>
        {data.map && (
          <Map
            map={data.map}
            google={this.props.google}
            variation={data.variation}
          />
        )}
      </div>
    );
  }

  _renderBody() {
    let { data } = this.state;

    return (
      <div style={{ position: "relative" }}>
        <div className="font-20" style={{ color: data.settings.primary_color }}>
          {data.title}
        </div>
        <div
          className="font-16"
          style={{ color: data.settings.secondary_color }}
        >
          {data.body}
        </div>
      </div>
    );
  }

  renderContent() {
    let { data } = this.state;

    const variation = data.variation || "map_left_text_right";

    const mobileTopPosition = "-70px";

    if (variation === "map_left_text_right") {
      return (
        <div className="row">
          <div className="col-md-6 col-sm-12">{this._renderMap()}</div>
          <div
            className="col-md-6 col-sm-12 text-center"
            style={{
              top: window.innerWidth < 600 ? mobileTopPosition : "180px",
            }}
          >
            {this._renderBody()}
          </div>
        </div>
      );
    } else if (variation === "map_top_text_bottom") {
      return (
        <div className="col-md-9 col-sm-12 map-top m-auto">
          {this._renderMap()}
          <div className="col text-center" style={{ top: "412px" }}>
            {this._renderBody()}
          </div>
        </div>
      );
    } else {
      return <div className="map-center">{this._renderMap()}</div>;
    }
  }

  render() {
    let { data } = this.state;

    const className = data.variation === "map_top_text_bottom" ? "section-full content-inner-2" : "section-full";

    return (
      <>
        {this._renderBlockStyle()}
        <div id={`section-${data.id}`} className={className}>
          {this.renderContent()}
        </div>
      </>
    );
  }
}
