import React from 'react'

import CountUp from 'react-countup';

export default class Block3 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){
    let {
      data
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${data.id} {
            background-color: ${data.settings.tertiary_color} !important;
          }
          #section-${data.id} {
            margin-top: ${data.settings.margin_top}px;
            margin-bottom: ${data.settings.margin_bottom}px;
          }
        `}}
      />
    )
  }

  render() {
    let {
      data
    } = this.state

    return (
      <>
        {this._renderBlockStyle()}
        <div id={`section-${data.id}`} className="section-full job-categories content-inner-2 bg-white">
          <div className="container">
            <div className="section-head d-flex head-counter clearfix">
              <div className="mr-auto">
                <h2 className="m-b5" style={{color: data.settings.primary_color}}>{data.title}</h2>
                <h6 className="fw3" style={{color: data.settings.secondary_color}}>{data.subtitle}</h6>
              </div>
              {
                data.text_infos.map(textInfo => {
                  return (
                    <a className="head-counter-bx" href={textInfo.body}>
                      <h2 className="m-b5 counter" style={{color: data.settings.quaternary_color}}>{textInfo.title}</h2>
                      <h6 className="fw3" style={{color: data.settings.quinary_color}}>{textInfo.subtitle}</h6>
                    </a>
                  )
                })
              }
            </div>
          </div>
        </div>
      </>
    )
  }

}
