import React from "react";

export default class Block15 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps);
  }

  _renderBlockStyle() {
    let { data } = this.state;

    return (
      <style
        dangerouslySetInnerHTML={{
          __html: `
          #section-${data.id} {
            background-color: ${data.settings.tertiary_color} !important;
            margin-top: ${data.settings.margin_top}px;
            margin-bottom: ${data.settings.margin_bottom}px;
          }
        `,
        }}
      />
    );
  }

  render() {
    let { data } = this.state;

    return (
      <>
        {this._renderBlockStyle()}
        <div id={`section-${data.id}`} className="section-full content-inner">
          <div className="container">
            <div className="section-head text-black text-left m-b20">
              <h2
                className="m-b10"
                style={{ color: data.settings.primary_color }}
              >
                {data.title}
              </h2>
            </div>
            <div className="row">
              {data.image_infos.map((imageInfo) => {
                return (
                  <>
                    <div className="col-lg-4 col-md-4 col-sm-12 m-b30">
                      <article className="feature feature-1">
                        <div
                          className="rdw-link-wrapper"
                          aria-label="rdw-link-control"
                        >
                          <a
                            className="rdw-option-wrapper t-link"
                            href={imageInfo.button?.url}
                            target="_blank"
                          >
                            <img
                              src={imageInfo.image.wide}
                              style={{
                                objectFit: "cover",
                                width: "100%",
                                height: 225,
                                borderRadius: "0 !important",
                              }}
                            />
                          </a>
                        </div>
                        <div className="feature__body boxed boxed--border b-18">
                          <div
                            data-offset-key="cq93h-0-0"
                            className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"
                          >
                            <a
                              style={{
                                color: data.settings.secondary_color,
                              }}
                            >
                              {imageInfo.title}
                            </a>
                          </div>
                          <div>
                            <div
                              className="font-14"
                              style={{ color: data.settings.quaternary_color }}
                            >
                              {imageInfo.subtitle}
                            </div>
                          </div>
                          <a
                            href={imageInfo.button?.url}
                            target="_blank"
                            title="READ MORE"
                            rel="bookmark"
                            className="site-button-link"
                            style={{ color: data.settings.quinary_color }}

                          >
                            <span className="fw6">Read More</span>
                          </a>
                          <a>
                            <div className="edit edit-button">
                              <i className="icon icon--xs icon-Pencil edit-button-i" />
                            </div>
                          </a>
                        </div>
                      </article>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}
