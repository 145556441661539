import React from 'react';
import moment from "moment";

import General from "../utils/General"
import Backend from "../utils/Backend";

import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import PageWrapper from "./components/common/PageWrapper";
import Banner from "./components/common/Banner";
import Detail from "./components/job/Detail";
import Description from "./components/job/Description";
import SearchBar from "./components/common/SearchBar";
import LazyLoadingList from "./components/common/LazyLoadingList";

export default class JobSearch extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      website: props.website,
      searchTerm: General.getUrlParameter("searchTerm"),
      stateId: General.getUrlParameter("stateId"),
      cityId: General.getUrlParameter("cityId"),
      countryId: General.getUrlParameter("countryId")
    }
    this.list = React.createRef()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderJob(job, index){
    let { website } = this.state
    return (
      <div className="col-lg-4 col-md-6 item">
        <div className='post-bx'>
          <a
            className="h5"
            href={`/companies/${job.company.slug}/jobs/${job.slug}`}
            style={{
              fontWeight: 600,
              color: "#232323",
              fontSize: 20
            }}
            onClick={e => {
              if(!website.search_result_redirect){
                return
              }
              e.preventDefault();
              Backend.saveExternalJobApplication(job)
              window.open(job.external_application_url, '_blank')
            }}
          >
            {job.title}
          </a>
          {!website.hide_company_names &&
            <div className='c-name'>
              <i className="fa fa-building-o icon"></i>
              <span className='ellipsis-1'>{job.company.name}</span>
            </div>
          }
          <ul>
            <li><i className="fa fa-map-marker"></i> {job.address.city.name}</li>
            <li><i className="fa fa-bookmark-o"></i> Full Time</li>
            {/*<li><i className="fa fa-clock-o"></i> Published {moment(job.published_at).timeFromNow()}</li>*/}
            { job.external_impression_url &&
              <img
                width={1}
                height={1}
                className="indeed-tmn-pxl"
                data-src={job.external_impression_url}
                style={{display: 'block', marginBottom:-1}}
              />
            }
          </ul>
        </div>
      </div>
    )
  }

  render() {
    let {
      searchTerm,
      countryId,
      stateId,
      cityId,
      website
    } = this.state

    let search = `search_term=${searchTerm || ''}&city_ids=${cityId || ''}&state_ids=${stateId || ''}&country_ids=${countryId || ''}&job_board_id=${website.job_board}`

    return (
      <>
        <div className="page-content bg-white pages job-search">
          <Banner
            title={`Browse ${window.Branding.Terminology.jobs}`}
          />
          <div className={"content-block"}>
            <SearchBar
              searchTerm={searchTerm}
              countryId={countryId}
              stateId={stateId}
              cityId={cityId}
              jobBoard={website}
              accentColor={website.primary_color}
            />
            <div className="section-full bg-white browse-job p-b50">
              <div className="container">
                <div className="post-job-bx browse-job-grid row">
                  <LazyLoadingList
                    ref={this.list}
                    endpoint={`${window.Api.Jobs}?${search}`}
                    renderItem={(job, index) => this._renderJob(job, index)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }

}
