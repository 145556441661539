import React from 'react'

import Link from 'react-router-dom'

export default class Block6 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){
    let {
      data
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${data.id} {
            background-color: ${data.settings.tertiary_color} !important;
          }
          #section-${data.id} {
            margin-top: ${data.settings.margin_top}px;
            margin-bottom: ${data.settings.margin_bottom}px;
          }
        `}}
      />
    )
  }

  render() {
    let {
      data
    } = this.state

    return (
      <>
        {this._renderBlockStyle()}
        <div id={`section-${data.id}`} className="section-full content-inner">
          <div className="container">
            <div className="row">
              {
                data.icon_infos.map(iconInfo => {
                  return (
                    <div className="col-lg-4 col-md-4 col-sm-12 m-b30">
                      <div className="icon-bx-wraper p-a30 center bg-gray radius-sm" style={{backgroundColor: data.settings.quinary_color}}>
                        <div className="icon-md text-primary m-b20">
                          <a className="icon-cell text-primary">
                            <i className={iconInfo.icon} style={{color: data.settings.quaternary_color}}></i>
                          </a>
                        </div>
                        <div className="icon-content">
                          <h5 className="dlab-tilte text-uppercase" style={{color: data.settings.primary_color}}>{iconInfo.title}</h5>
                          <p style={{color: data.settings.secondary_color}}>{iconInfo.subtitle}</p>
                        </div>
                        { iconInfo.button?.url &&
                          <div className="dez-post-readmore blog-share mt-3">
                            <a href={iconInfo.button?.url} title="Click Here" rel="bookmark" className="site-button-link" target="_blank">
                              <span
                                className="fw6"
                                style={{color: data.settings.quaternary_color}}
                              >
                                { iconInfo.button?.title || "CLICK HERE"}
                              </span>
                            </a>
                          </div>
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </>
    )
  }

}
