import React from 'react';

import moment from 'moment'
import renderHTML from 'react-render-html';

export default class Description extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      article: props.article,
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      article,
      showVideo
    } = this.state

    return (
      <>
        <div className="col-lg-8 col-md-7 m-b10">
          <div className="blog-post blog-single blog-style-1">
            <div className="dez-post-meta">
              <ul className="d-flex align-items-center">
                <li className="post-author"><i className="fa fa-user"/>By <a href={"#"}>{article.author.first_name}</a> </li>
              </ul>
            </div>
            <div className="dez-post-title">
              <h4 className="post-title m-t0">{article.title}</h4>
            </div>
            <div className="dez-post-media dez-img-effect zoom-slow m-t20">
              <img src={article.thumbnail.wide} alt="" />
            </div>
            <div className="dez-post-text v2">
              {renderHTML(article.body)}
            </div>

            { article.video &&
              <div className="container">
                <div className="section-head text-black text-center mb-0">

                  {/* stats : Video row */}
                  <div class="row mt-4 text-center">
                    <div class={"col-md-12 col-lg-12"}>
                      <div class="video-cover border--round">
                          { !showVideo ? (
                            <>
                              <div class="background-image-holder">
                                  <img alt="" src={article.video.image.original} style={{objectFit: 'cover'}} />
                               </div>
                              <div
                                class="video-play-icon"
                                onClick={() => this.setState({ showVideo: true })}
                              >
                              </div>
                            </>
                          ) : (
                            <iframe
                              width="100%"
                              height="100%"
                              src={article.video.url}
                              frameborder="0"
                              allowfullscreen="true"
                              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                            />
                          )
                        }
                      </div>

                    </div>
                  </div>
                  {/* stats : Video end */}
                </div>
              </div>
            }

            <div className="dez-post-tags clear">
              <div className="post-tags">
                {
                  article.tags.map(tag => {
                    return (
                      <a href={""}>{tag.name}</a>
                    )
                  })
                }
              </div>
            </div>
            <div className="dez-divider bg-gray-dark op4"><i className="icon-dot c-square"></i></div>


          </div>
        </div>
      </>
    )
  }

}
