import FetchHelper from './FetchHelper'
import AuthManager from './AuthManager'

export default class Backend {
  static getSearchLocationIds(country, state, city){
    if(country && state && city){
      return Backend.getJobBoardCities(country, state, city)
    } else if(country && state && !city){
      return Backend.getJobBoardStates(country, state)
    } else if(country){
      return Backend.getJobBoardCountry(country)
    } else{
      return new Promise((resolve, reject) => resolve([]))
    }
  }

  static getJobBoardCountry(country){
    return FetchHelper.get(`${window.Api.JobBoardCountries}?search_term=${country}`)
  }

  static getJobBoardStates(country, state){
    if(!state){
      return FetchHelper.get(`${window.Api.JobBoardStates}?country_search_term=${country}&paginated=false`)
    }else{
      return FetchHelper.get(`${window.Api.JobBoardStates}?search_term=${state}&country_search_term=${country}`)
    }
  }

  static getJobBoardCities(country, state, city){
    return FetchHelper.get(`${window.Api.JobBoardCities}?city_search_term=${city}&state_search_term=${state}&country_search_term=${country}`)
  }

  // static getJobs(filter) {
  //   let endpoint = window.Api.Jobs;
  //   if (filter) {
  //     endpoint = endpoint + filter
  //   }
  //   return FetchHelper.get(`${endpoint}`)
  // }

  static getCompany(company_slug) {
    return FetchHelper.get(`${window.Api.Companies}/${company_slug}`)
  }

  static getJobs(type) {
    return FetchHelper.get(`${window.Api.Jobs}?page_size=5${type === "featured" ? '&featured=true' : ''}`)
  }

  static getJob(job_slug) {
    return FetchHelper.get(`${window.Api.Jobs}/${job_slug}`, )
  }

  static saveExternalJobApplication(job) {
    return FetchHelper.post(`${window.Api.Jobs}/${job.id}/external-application-click`)
  }

  static getFilters(filter) {
    return FetchHelper.get(`${window.Api.Forms}${filter}`)
  }

  static getJobBoardJobTypes(filter) {
    return FetchHelper.get(`${window.Api.JobBoardJobTypes}${filter}`)
  }

  static uploadFile(file, endpoint, params) {
    let data = new FormData()

    data.append('file', file, file.name)
    for (var key in params) {
      data.append(key, params[key])
    }

    return FetchHelper.post(endpoint, data, true)
  }

  static uploadImage(image, type) {

    let data = new FormData()

    data.append('file', image, image.name)
    data.append('type', type)

    return FetchHelper.post(window.Api.Images, data, true)
  }

  static getPage(pageSlug, pageType, websiteUrl) {
    
    let page = pageType === 'search' ? 'search=true' : `${pageType}_slug=${pageSlug}`
    return FetchHelper.get(`${window.Api.Pages}/find?${page}&website_url=${websiteUrl}`)
  }

  static getArticles(filter) {
    let endpoint = window.Api.Articles;
    // if (filter) {
    //   endpoint = endpoint + filter
    // }
    return FetchHelper.get(`${endpoint}?order_by=-published_at`)
  }

  static getArticle(articleSlug) {
    return FetchHelper.get(`${window.Api.Articles}/${articleSlug}`)
  }

  static getSimilarArticles(articleID){
    return FetchHelper.get(`${window.Api.Articles}?similar_to_article_id=${articleID}`)
  }

  static postApplications(application) {
    return FetchHelper.post(`${window.Api.Applications}`, application)
  }

  static createLead(lead) {
    return FetchHelper.post(`${window.Api.Leads}`, lead)
  }

  static getGallery(type, id) {
    let filter = '';
    if (type === 'article') {
      filter = `?article_id=${id}`
    } else if (type == 'company') {
      filter = `?company_id=${id}`
    }
    return FetchHelper.get(`${window.Api.Articles}/${filter}`)
  }

  static postAlert(alert) {
    return FetchHelper.post(`${window.Api.Alerts}`, alert)
  }

  static getSearchableLocations(jobBoardID) {
    return FetchHelper.get(`${window.Api.SearchableLocations}?job_board_id=${jobBoardID}`)
  }

  static getJobPostForm(jobBoardID) {
    return FetchHelper.get(`${window.Api.Forms}?job_board_id=${jobBoardID}&type=job_post`)
  }

  static getModals(filter) {
    return FetchHelper.get(`${window.Api.Modals}?type=${filter}`)
  }

  static verifyPostJobEmail(data) {
    return FetchHelper.post(window.Api.VerifyEmail, data)
  }

}
