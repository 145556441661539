import React from 'react';

import {Link} from 'react-router-dom';

export default class Footer extends React.Component{

	constructor(props) {
		super(props);

		this.state = {
			footer: props.footer,
			website: props.website
		}
	}

	componentDidMount() {

	}

	_renderNavItems(footer, navItems){
		return navItems.map(navItem => {
			return (
				<li><a href={navItem.url} style={{color: footer.settings.secondary_color}}>{navItem.text}</a></li>
			)
		})
	}

	_renderColumns(footer){
		let className = "text-left"
		if(footer.variation == "center_align"){
			className = "text-center"
		}
		else if(footer.variation == "right_align"){
			className = "text-right"
		}
		return footer.columns.map(column => {
			return (
				<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
					<div className={`widget border-0 ${className}`}>
						<h5 className="m-b30" style={{color: footer.settings.primary_color}}>{column.title}</h5>
						<ul className="list-2 w10 list-line">
							{this._renderNavItems(footer, column.nav_items)}
						</ul>
					</div>
				</div>
			)
		})
	}

	render(){
		let {
			footer,
			website
		} = this.state

		if(!footer || this.props.hide){
			return null
		}

		return(
			<footer className="site-footer">
				<div className="footer-top" style={{backgroundColor: footer.settings.tertiary_color, borderColor: footer.settings.tertiary_color}}>
					<div className="container">
						<div className="row justify-content-center">
							{this._renderColumns(footer)}
						</div>
					</div>
				</div>

				<div className="footer-bottom" style={{backgroundColor: footer.settings.tertiary_color}}>
					<div className="container">
						<div className="row">
							<div className="col-lg-12 text-center" style={{color: footer.settings.primary_color}}>
								<span>{footer.fine_print}</span>
							</div>
						</div>
					</div>
				</div>
			</footer>
		)
	}

}
