import React from 'react';

import moment from 'moment'

import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import PageWrapper from "./components/common/PageWrapper";
import Banner from "./components/common/Banner";
import NotFound from "./components/common/NotFound";

import Detail from "./components/job/Detail";
import Description from "./components/article/Description";
import Backend from "../utils/Backend";
import renderHTML from 'react-render-html';
import SimilarArticles from "./components/article/SimilarArticles";

export default class Article extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      article: props.article,
      website: props.website
    }
  }

  componentDidMount() {
    // this._loadArticle(this.props.match.params.article_slug)
    // this._loadArticle('title')
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({nextProps})
    // this._loadArticle(nextProps.match.params.article_slug)
    // this._loadArticle('title')
  }

  _loadArticle(articleSlug){
    Backend.getArticle(articleSlug)
      .then(article => {
        this.setState({
          article
        })
      }).catch(() => {
      this.setState({notFound: true})
    })
  }

  render() {
    let {
      article,
      website
    } = this.state

    if(!article) return <NotFound website={website}/>

    return (
      <>
        <div className="page-content bg-white">
          <Banner
            title={article.title}
            image={article.banner}
          />
          <div className={"content-area"}>
            <div className="container">
              <div className={"row"}>
                <Description
                  article={article}
                />
                <SimilarArticles
                  article={article}
                />
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }

}
