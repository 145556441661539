import React from 'react';

import Select from "./Select";

import Template from "../../../utils/Template";
import Backend from "../../../utils/Backend";

export default class SearchBar extends React.Component{

	constructor(props) {
		super(props);

		this.state = {
			searchTerm: props.searchTerm,
			countryId: props.countryId,
			stateId: props.stateId,
			cityId: props.cityId,
			jobBoard: props.jobBoard
		}
	}

	componentDidMount() {
		this._loadLocations()
		Template.searchInput()
	}

	componentWillReceiveProps(nextProps){
		this.setState({
			searchTerm: nextProps.searchTerm,
			countryId: nextProps.countryId,
			stateId: nextProps.stateId,
			cityId: nextProps.cityId,
		})
	}

	_loadLocations() {
		let {
			jobBoard,
		} = this.state

		Backend.getJobPostForm(jobBoard.id)
			.then(form => {
				this.setState({
					locations: form.cities
				}, () => {
					this._formatLocations()
				})
			})
	}

	_formatLocations() {
		let {
			locations,
		} = this.state

		let searchableLocations = []

		locations.map(city => {
			searchableLocations.push({value: city.id, label: city.name, type: 'city'})
		})

		searchableLocations.sort((a, b) => a.label.localeCompare(b.label))

		let selectedLocation = this._getSelectedLocationValue(searchableLocations)

		this.setState({searchableLocations, selectedLocation})
	}

	_getSelectedLocationValue(searchableLocations) {

		let {
			cityId,
			stateId,
			countryId,
		} = this.state

		if (searchableLocations == null) {
			return null
		}

		return searchableLocations.find(option => {
      return cityId == option.value
    })
	}

	_handleLocationChange(location) {
		let countryId = '';
		let stateId = '';
		let cityId = '';
		if(!location){
			// do nothing
		} else if (location.type === 'country') {
			countryId = location.value
		} else if(location.type === 'state'){
			stateId = location.value
		} else if(location.type === 'city'){
			cityId = location.value
		}
		this.setState({selectedLocation: location, countryId, stateId, cityId})
	}

	_handleSearch() {
		let {
			searchTerm,
			countryId,
			stateId,
			cityId,
			jobBoard,
			premium
		} = this.state

		if(this.props.onSearchTermUpdated){
			this.props.onSearchTermUpdated({ searchTerm, countryId, stateId, cityId })
			return
		}

		window.location.href = `/search/jobs?searchTerm=${searchTerm || ''}&cityId=${cityId || ''}&stateId=${stateId || ''}&countryId=${countryId || ''}&job_board_id=${jobBoard.job_board}`
	}

	render(){
		let {
			searchTerm,
			selectedLocation,
			searchableLocations,
		} = this.state


		return(
			<div className="section-full browse-job-find">
				<div className="container">
					<div className="find-job-bx">
						<form className="dezPlaceAni">
							<div className="row">
								<div className="col-lg-5 col-md-5">
									<div className="form-group">
										<label>{window.Branding.Terminology.job} Title, Keywords, or Phrase</label>
										<div className="input-group">
											<input
											    autofocus={searchTerm && "autofocus"}
												type="text"
												className="form-control"
												value={searchTerm}
												style={{
													borderColor: this.props.accentColor
												}}
											 	onChange={e => {
												 	let value = e.target.value;
												 	searchTerm = value;
												 	this.setState({searchTerm})
											 	}}
											/>
											<div className="input-group-append">
												<span
													className="input-group-text"
													style={{
														color: this.props.accentColor,
														borderColor: this.props.accentColor
													}}
												>
													<i className="fa fa-search"></i>
												</span>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-5 col-md-5" style={{zIndex: 9999999}}>
									<div className="form-group">
									{ searchableLocations &&
										<Select
												value={selectedLocation?.value}
												options={searchableLocations}
												getOptionLabel={searchableLocation => searchableLocation.label}
												getOptionValue={searchableLocation => searchableLocation.value}
												placeholder="Select Location"
												className="form-control h-auto border-0 c-selectbox"
												isClearable={true}
												onSelected={option => {
													this._handleLocationChange(option)
												}}
										/>
									}
									</div>
								</div>
								<div className="col-lg-2 col-md-2">
									<button
										type="submit"
										className="site-button btn-block custom-btn-class"
										onClick={e => {
											e.preventDefault()
											this._handleSearch()
										}}
									>
										Find {window.Branding.Terminology.job}
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}

}

const customStyles = {
	option: (styles, {data, isDisabled, isFocused, isSelected}) => {
		if (data.type === 'state') {
			return styles;
		}
		return {
			...styles,
			backgroundColor: isFocused ? '#B2D4FF' : '#f5f5f5',
			color: isSelected ? '#fff' : '#666666',
			cursor: 'default',
		};
	},
	control: (styles) => {
		return {
			...styles,
			border: '1px solid #ececec',
			borderRadius: 6,
			background: '#fcfcfc',
			fontWeight: 400,
			fontSize: 16,
			minHeight: 45,
			height: 45,
		}
	},
	valueContainer: (styles) => {
		return {
			...styles,
			height: 44,
			padding: '0 10px',
			overflow: 'unset'
		}
	},
	input: (styles) => {
		return {
			...styles,
			margin: 0,
			paddingLeft: 1
		}
	},
	indicatorSeparator: (styles) => {
		return {
			display: 'none'
		}
	},
	indicatorsContainer: (styles) => {
		return {
			...styles,
			height: 44
		}
	},
};
