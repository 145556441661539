import React from 'react'

export default class Block9 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){
    let {
      data
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${data.id} {
            background-color: ${data.settings.tertiary_color} !important;
          }
          #section-${data.id} {
            margin-top: ${data.settings.margin_top}px;
            margin-bottom: ${data.settings.margin_bottom}px;
          }
        `}}
      />
    )
  }

  render() {
    let {
      data
    } = this.state

    return (
      <>
        {this._renderBlockStyle()}
        <div id={`section-${data.id}`} className="content-block">
          <div className="section-full content-inner-2 portfolio-box">
            <div className="container">
              <div className="section-head text-black text-center m-b20">
                <h2 className="m-b10" style={{color: data.settings.primary_color}}>{data.title}</h2>
                <p style={{color: data.settings.secondary_color}}>{data.subtitle}</p>
              </div>
              <div>
                <ul className="dez-gallery-listing gallery-grid-4 gallery mfp-gallery sp10 row">
                  {
                    data.images.map(image => {
                      return (
                        <li className=" col-lg-4 col-md-3 col-sm-6 col-6 web design">
                          <div className="dez-box dez-gallery-box">
                            <div className="dez-media dez-img-overlay1 dez-img-effect">
                              <a href={"#"}> <img src={image.wide} style={{height: 255, objectFit: 'cover'}}  alt="" /> </a>
                            </div>
                          </div>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>

            </div>
          </div>
        </div>
      </>
    )
  }

}