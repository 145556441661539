import React from 'react';
import { Helmet } from 'react-helmet';

export default class MetaTags {
  static render(title, description, keywords, url, imageUrl, type, noindex = false, favIconUrl = null){

    let children = []

    if(noindex){
      children.push(<meta name="robots" content="noindex" />)
    }

    children.push(<meta name="twitter:card" content="summary_large_image" />)

    if(title){
      children.push(<title>{title}</title>)
      children.push(<meta property="og:title" content={title} />)
      children.push(<meta name="twitter:title" content={title} />)
    }

    if(favIconUrl){
      children.push(<link rel="icon" type="image/x-icon" href={favIconUrl}/>)
    }

    if(description){
      children.push(<meta name="description" content={description} />)
      children.push(<meta property="og:description" content={description} />)
      children.push(<meta name="twitter:description" content={description} />)
    }

    if(keywords){
      children.push(<meta name="keywords" content={keywords} />)
    }

    if(imageUrl){
      children.push(<meta property="og:image" content={imageUrl} />)
      children.push(<meta name="twitter:image" content={imageUrl} />)
    }

    if(url){
      children.push(<meta property="og:url" content={url} />)
    }

    if(url){
      children.push(<meta property="og:type" content={type} />)
    }

    return (
      <Helmet>
        { children }
      </Helmet>
    )
  }
}
