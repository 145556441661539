import React from 'react'

import Block1 from "./1";
import Block2 from "./2";
import Block3 from "./3";
import Block4 from "./4";
import Block5 from "./5";
import Block6 from "./6";
import Block7 from "./7";
import Block8 from "./8";
import Block9 from "./9";
import Block10 from "./10";
import Block11 from "./11";
import Block12 from "./12";
import Block13 from "./13";
import Block14 from "./14"
import Block15 from './15';

export default class ContentBlock extends React.Component {
  blocks = {
    Block1,
    Block2,
    Block3,
    Block4,
    Block5,
    Block6,
    Block7,
    Block8,
    Block9,
    Block10,
    Block11,
    Block12,
    Block13,
    Block14,
    Block15
  }

  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      blockId: props.data.block_id,
      jobBoard: props.jobBoard
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      ...nextProps,
      blockId: nextProps.data.block_id,
    })
  }

  render() {
    let blockName = `Block${this.state.blockId}`
    const Block = this.blocks[blockName]

    return (
      <Block
        data={this.state.data}
        jobBoard={this.state.jobBoard}
      />
    )
  }
}
