import React from 'react';

export default class Detail extends React.Component{

	constructor(props) {
		super(props);

		this.state = {
			job: props.job,
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState(nextProps)
	}

	render(){
		let {
			job
		} = this.state

		return(
			<div className="col-lg-4">
				<div className="sticky-top">
					<div className="row">
						{/*<div className="col-lg-12 col-md-6">*/}
						{/*	<div className="m-b30">*/}
						{/*		<img src={require("../../../assets/images/blog/grid/pic1.jpg")} alt="" />*/}
						{/*	</div>*/}
						{/*</div>*/}
						<div className="col-lg-12 col-md-6">
							<div className="widget bg-white p-lr20 p-t20  widget_getintuch radius-sm">
								<h4 className="text-black font-weight-700 p-t10 m-b15">
									{window.Branding.Terminology.job} Details
								</h4>
								<ul>
									<li>
										<i className="ti-location-pin"/>
										<strong className="font-weight-700 text-black">Address</strong>
										<span className="text-black-light"> {job?.address.raw} </span>
									</li>
									{
										job.salary &&
										<li>
											<i className="ti-money"/>
											<strong className="font-weight-700 text-black">Salary</strong>
											$800 Monthy
										</li>
									}
									<li>
										<i className="ti-shield"/>
										<strong className="font-weight-700 text-black">Experience</strong>
										{job.level.name}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

}
