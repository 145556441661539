import React, { Component } from 'react';


import Color from '../../../utils/Color'
import MetaTags from '../../../utils/MetaTags'

export default class Branding extends Component  {

  render(){

    let { website } = this.props

    return (
      <h1
        className="text-center text-primary"
        style={{
          marginTop: 100
        }}
      >
        <h1 style={{color: website.primary_color, fontSize: 120}}>404</h1>
        <h6 className="mb-10">The page you are looking for could not be found</h6>
        <a
          href="/"
          className="site-button mt-3"
          style={{
            fontSize: 14
          }}
        >
          Go Home
        </a>
      </h1>
    )
  }


}
