import React from 'react';

import Backend from "../../../utils/Backend";
import moment from "moment";

export default class SimilarArticles extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      article: props.article,
    }
  }

  componentDidMount() {
    this._loadSimilarArticles()
  }

  _loadSimilarArticles(){
    let {
      article
    } = this.state

    Backend.getSimilarArticles(article.id)
      .then(response => {
        this.setState({
          articles: response.results
        })
      }).catch(() => {
      this.setState({notFound: true})
    })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderArticles(){
    let {
      articles
    } = this.state

    return articles.map(article => {
      return (
        <a
          className="widget-post clearfix"
          href={`/article/${article.slug}`}
        >
          <div className="dez-post-media">
            <img src={article.thumbnail.small} width="200" height="143" alt="" />
          </div>
          <div className="dez-post-info">
            <div className="dez-post-header">
              <h6 className="post-title" style={{textTransform: "none"}}><a href={"/blog-details"}></a>{article.title}</h6>
            </div>
          </div>
        </a>
      )
    })
  }

  render() {
    let {
      articles
    } = this.state

    return (
      <>
        <div className="col-lg-4 col-md-5 sticky-top">
          <aside  className="side-bar">
            {/*<div className="widget">*/}
            {/*  <h6 className="widget-title style-1">Search</h6>*/}
            {/*  <div className="search-bx style-1">*/}
            {/*    <form*/}
            {/*      role="search"*/}
            {/*      method="post"*/}
            {/*    >*/}
            {/*      <div className="input-group">*/}
            {/*        <input name="text" className="form-control" placeholder="Enter your keywords..." type="text" />*/}
            {/*        <span className="input-group-btn">*/}
            {/*          <button type="submit" className="fa fa-search text-primary"/>*/}
            {/*        </span>*/}
            {/*      </div>*/}
            {/*    </form>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {
              articles?.length > 0 &&
              <div className="widget recent-posts-entry">
                <h6 className="widget-title style-1">Recent Posts</h6>
                <div className="widget-post-bx">
                  { this._renderArticles() }
                </div>
              </div>
            }
          </aside>
        </div>
      </>
    )
  }

}
