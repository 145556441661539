import React from "react";

import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import TagManager from "react-gtm-module";

import Api from "./constants/Api";
import General from "./constants/General";

import "./assets/css/plugins.css";
import "./assets/css/style.css";
import "./assets/css/custom.css";
import './assets/css/socicon.css'
import "./assets/css/template.css";
import "./assets/css/skin/skin-1.css";
import "./assets/plugins/slick/slick.min.css";
import "./assets/plugins/slick/slick-theme.min.css";
import './assets/sass/app.scss'

import Page from "./pages/Page";

import Header from "./pages/components/common/Header";
import Footer from "./pages/components/common/Footer";
import Branding from "./pages/components/common/Branding";
import PageWrapper from "./pages/components/common/PageWrapper";

import Job from "./pages/Job";
import JobSearch from "./pages/JobSearch";
import Article from "./pages/Article";
import Company from "./pages/Company";

import Backend from "./utils/Backend";

import $ from "jquery";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jobMode: false,
      pageMode: false,
      searchMode: false,
      articleMode: false,
      companyMode: false,
    };
  }

  componentDidMount() {
    let scripts = [];

    this._setup();

    scripts.push(
      this._getScript(
        process.env.PUBLIC_URL + "/assets/js/jquery-3.3.1.slim.min.js"
      )
    );

    scripts.push(
      this._getScript(process.env.PUBLIC_URL + "/assets/js/popper.min.js")
    );

    scripts.push(
      this._getScript(process.env.PUBLIC_URL + "/assets/js/bootstrap.min.js")
    );

    setTimeout(() => {
      this._addScripts(scripts);
    }, 3000);
  }

  _getScript(path) {
    const script = document.createElement("script");

    script.src = path;

    return script;
  }

  // TO:DO remove need for sleep, the issue is vendors.bundle.js, takes too long to load
  // and scripts.bundle.js executes before it finishes causing all sorts of errors.
  async _addScripts(scripts) {
    for (var i = 0; i < scripts.length; i++) {
      let script = scripts[i];
      document.body.appendChild(script);
      await this._sleep(500);
    }
  }

  _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async _setup() {
    let domain = window.location.host;

    if (domain.includes("localhost")) {
      domain = "amply-site-dev.herokuapp.com";
    }

    let pathname = window.location.pathname;

    let companySlug = null;
    let jobSlug = null;
    let articleSlug = null;
    let pageSlug = null;

    let pageMode = false;
    let jobMode = false;
    let companyMode = false;
    let articleMode = false;
    let searchMode = false;
    let companiesMode = false;
    let articlesMode = false;

    if (pathname !== null) {
      pathname = pathname
        .substring(1, window.location.pathname.length)
        .split("/");

      jobMode = pathname.includes("companies") && pathname.includes("jobs");
      companyMode = pathname.includes("companies");
      articleMode = pathname.includes("article");
      searchMode = pathname.includes("search") && pathname.includes("jobs");
      companiesMode =
        pathname.includes("search") && pathname.includes("companies");
      articlesMode =
        pathname.includes("search") && pathname.includes("articles");

      if (
        !jobMode &&
        !companyMode &&
        !articleMode &&
        !searchMode &&
        !companiesMode &&
        !articlesMode
      ) {
        pageMode = true;
        pageSlug = pathname[0] || "index";
      } else if (jobMode) {
        companySlug = pathname[pathname.indexOf("companies") + 1];
        jobSlug = pathname[pathname.indexOf("jobs") + 1];
      } else if (companyMode) {
        companySlug = pathname[pathname.indexOf("companies") + 1];
      } else if (articleMode) {
        articleSlug = pathname[pathname.indexOf("article") + 1];
      }
    }

    this.setState(
      {
        pageMode,
        jobMode,
        companyMode,
        articleMode,
        searchMode,
        companiesMode,
        articlesMode,
        companySlug,
        jobSlug,
        articleSlug,
        pageSlug,
      },
      () => {
        this._loadPage(domain);
      }
    );
  }

  _setTracking(website) {
    let gtmId = website.gtm_id;

    if (!gtmId) {
      return;
    }

    const tagManagerArgs = {
      gtmId: gtmId,
    };

    TagManager.initialize(tagManagerArgs);
  }

  _loadPage(domain) {
    let {
      jobMode,
      pageMode,
      companyMode,
      articleMode,
      websiteUrl,
      companySlug,
      jobSlug,
      articleSlug,
      pageSlug,
    } = this.state;

    let type = pageMode
      ? "page"
      : jobMode
      ? "job"
      : companyMode
      ? "company"
      : articleMode
      ? "article"
      : "search";
    let slug = pageMode
      ? pageSlug
      : jobMode
      ? `${jobSlug}&company_slug=${companySlug}`
      : companyMode
      ? companySlug
      : articleSlug;

    Backend.getPage(slug, type, domain)
      .then((response) => {
        let { website, page, job, article, company, footer, menu } = response;
        window.Branding = {
          Terminology: {
            article: website.article_name,
            articles: website.articles_name,
            company: website.company_name,
            companies: website.companies_name,
            job: website.job_name,
            jobs: website.jobs_name,
          },
        };
        window.JOB_BOARD_ID = website.job_board;
        this.setState(
          {
            website,
            page,
            job,
            article,
            company,
            footer,
            menu,
          },
          () => {
            this._setTracking(website);
            this._handleHash();
          }
        );
      })
      .catch(() => {
        this.setState({ notFound: true });
      });
  }

  _renderContent() {
    let {
      website,
      page,
      job,
      article,
      jobMode,
      searchMode,
      articleMode,
      companyMode,
      company,
    } = this.state;


    if (jobMode) {
      return <Job job={job} website={website} />;
    }

    if (searchMode) {
      return <JobSearch website={website} />;
    }

    if (articleMode) {
      return <Article article={article} website={website} />;
    }

    if (companyMode) {
      return <Company company={company} website={website} />;
    }

    return <Page page={page} website={website} />;
  }

  _handleHash() {
    let hash = window.location.hash;

    if (hash === "") {
      return;
    }

    if ($(hash).length === 0) {
      return;
    }

    setTimeout(() => {
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: $(hash).offset().top - 100,
          },
          800,
          "swing"
        );
    }, 1200);
  }

  render() {
    let { website, page, job, article, footer, menu } = this.state;

    if (!website) return null;

    return (
      <BrowserRouter>
        <PageWrapper>
          <Header menu={menu} website={website} />

          {this._renderContent()}
          <Footer
            footer={footer}
            website={website}
            hide={page?.primary_footer == false}
          />
          <Branding job={job} page={page} article={article} website={website} />
        </PageWrapper>
      </BrowserRouter>
    );
  }
}
